import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import * as ROUTES from "./constants/routes";
import React, { lazy, Suspense } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
   const Home = lazy(() => import("./pages/Home/index"));
  const loading = (
   <div class="loader">
  <div class="circle">
    <div class="dot"></div>
    <div class="outline"></div>
  </div>
  <div class="circle">
    <div class="dot"></div>
    <div class="outline"></div>
  </div>
  <div class="circle">
    <div class="dot"></div>
    <div class="outline"></div>
  </div>
  <div class="circle">
    <div class="dot"></div>
    <div class="outline"></div>
  </div>
</div>
  );

  const Terms = lazy(() => import("./pages/terms"));
  const About = lazy(() => import("./pages/About"));
  const Services = lazy(() => import("./pages/Services"));
  const Contact = lazy(() => import("./pages/Contact"));
  const Portfolio = lazy(() => import("./pages/portfolio"));
  const Quote = lazy(() => import("./pages/quote"));
  return (
    <Router>
    <Suspense fallback={loading}>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.TERMS} element={<Terms />} />
        <Route path={ROUTES.SERVICES} element={<Services />} />
        {/* <Route path={ROUTES.PORTFOLIO} element={<Portfolio />} /> */}
        <Route path={ROUTES.CONTACT} element={<Contact />} />
        <Route path={ROUTES.REQUESTQUOTE} element={<Quote />} />
        </Routes>
        </Suspense>
    </Router>
  );

  }

export default App;
